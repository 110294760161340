@charset "UTF-8";

/*-----------------------------------------------------------
    toggleMenu setting
-----------------------------------------------------------*/
[data-toggleMenu="main"] { top: 0; left: 0; position: fixed; z-index: 100; pointer-events: none;
    &.open { pointer-events: auto;}
    @include pc { position: static; pointer-events: auto;}
}
[data-toggleMenu="container"] { height: 100vh; width: 100vw; left: 0; top: -100vh; position: absolute; overflow: auto; -webkit-overflow-scrolling: touch;
    transition: transform 0.3s;
    .open & { transform: translateY(100vh);}
    @include pc { transform:none; position: static; height: auto; width: auto; overflow: visible;}
}

.toggleMenu {
    &__button { display: block; width: 5rem; height: 5rem; position: relative; z-index: 10000; background: #fff url(../images/icon_menu.png) no-repeat center / 2.6rem auto;
        &.open { background-image: url(../images/icon_menu_close.png); background-size: 2.7rem;}
    }
    &__overlay { top: 0; bottom: 0; right: 0; left: 0; position: fixed; z-index: 1; background-color: rgba(255,255,255,0.3);}
}

/* gnav */
.gnav {
    @include pc { align-self: center; margin-left: auto;}
    a { text-decoration: none;}
    &__container { background-color: #fff;
        @include sp { padding-top: 5rem;}
    }
    &__list { padding-top: 3rem; background: #fff;
        @include pc { display: flex; padding-top: 0;}
        >li { font-size: 1.8rem;
            @include pc { margin: 0 15px; font-size: 14px;}
            > a {
                @include sp { padding: 3rem 4.5rem; display: block;}
            }
            &.contact a { color: #e11427;}
        }
    }
}
