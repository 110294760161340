
.topFv {
    &__bg { margin-bottom: 3.5rem;
        @include pc { margin-bottom: 150px;}
    }
    &__container {
        @include pc { position: relative; height: 44.5vw; min-height: 520px;}
    }
    &__mainVisual {
        @include pc { height: 100%;
            div { height: 100%!important;}
            img { height: 100%; max-width: none;}
        }
    }
    &__content { background-color: #fff;
        @include pc { display: flex; flex-direction: column-reverse; position: absolute; top: 0; left: 50%; height: 100%; width: 50%;}
    }
    &__subVisual { display: flex;
        @include pc { flex: 0 1 auto; height: 58%; }
        &-col { flex: 0 0 50%; overflow: hidden;
            @include pc {
                div { height: 100%!important;}
                img { max-width: none; height: 100%;}
            }
        }
    }
    &__catchCopy { padding: 3.5rem 1.5rem;
        p { font-size: 1.5rem; line-height: 1.6;}
        p.main { margin-bottom: 1.5rem; font-size: 2rem; line-height: 1.2;}
        @include pc { flex: 1 1 auto; display: flex; align-items: center; padding: 0 0 0 11%;
            p { font-size: 14px;}
            p.main { margin-bottom: 20px; font-size: 26px;}
        }
    }
}

.topHeading { font-size: 1.4rem; color: #e11428;
    @include pc { font-size: 24px;}
    &-en { margin-bottom: .5rem; font-size: 3.6rem; letter-spacing: .1em; color: #555555;
        @include pc { margin-bottom: 10px; font-size: 48px;}
    }
}

.topAbout {
    &__container { margin: 0 -1.5rem 11rem 14rem; position: relative;
        a { color: #fff;}
        @include pc { margin-left: 160px; margin-bottom: 60px;}
    }
    &__header { position: absolute; top: 2.5rem; left: -5.4rem; z-index: 1; text-align: center;
        .topHeading-en p { line-height: 1; font-size: 3.6rem;}
        .black { color: #555555;}
        .white { color: #fff;}
        @include pc { left: -165px; top: 50%; transform: translateY(-50%); text-align: left;}
    }
    &__content { background-color: #131313; color: #fff; font-size: 1.5rem; position: relative;
        >a { display: block; padding: 12rem 1.5rem 5rem 3rem; display: block; text-decoration: none;}
        p+p { margin-top: 1.5em;}
        .learnMore { margin-top: 4.5rem;}
        @include pc { font-size: 14px;
            >a {padding: 40px 40px 40px 210px; }
            .learnMore { margin-top: 15px;}
        }
        &-bg {
            @include pc { margin-right: calc(50% - 50vw); background-color: #131313;}
        }
        &-body { position: relative;}
    }
}


.topBusinessOverview {
    &__container { margin-bottom: 8.5rem;
        @include pc { margin-bottom: 75px;}
    }
    &__header { margin-bottom: 5rem; text-align: center;
        @include pc { margin-bottom: 110px;}
    }
    &__intro{ margin-bottom: 8.5rem; font-size: 1.5rem;
        p { line-height: 1.33;}
        .learnMore { margin-top: 3rem; text-align: center;
            a { text-decoration: none;}
        }
    }
    &__content { padding-top: 14rem; position: relative;
        @include pc { padding-top: 115px; width: 960px;}

        &-image { height: 30rem; width: 30rem; right: -1.5rem; top: 0; position: absolute; z-index: 1; overflow: hidden;
            >img { max-width: none; height: 100%;}
            @include pc { height: 493px; width: 617px; right: -155px;}
        }
        &-body { width: 30rem; height: 24rem; left: -1rem; position: relative; background-color: #f9f0f1;
            @include pc { height: 475px; width: 617px; left: 0;
                a { display: block; height: 100%; padding: 120px 0 0 80px; text-decoration: none;}
                p { font-size: 24px; line-height: 1.75;}
                .learnMore { margin-top: 30px;}
            }
            &-bg { background-color: #f9f0f1;}
            &-inner { position: relative;}
        }
    }
}


.topBusinessOverviewItems {
    &__container { padding-bottom: 10rem;}
    &__list-column3 {
        a { text-decoration: none;}
        .title { margin-top: 1rem; font-size: 1.5rem; line-height: 1.1; font-weight: bold; text-align: center;}
        .thumb { height: 16rem;
            >img { object-fit: cover; width: 100%; height: 100%;}
        }
        @include sp { margin-bottom: 3rem;
            li+li { margin-top: 3rem;}
        }
        @include pc { margin-bottom: 120px; display: flex; justify-content: space-between;
            .title { font-size: 18px;}
            .item { flex: 0 1 300px;
                &:not(:first-child) { margin-left: 10px;}
                &:nth-child(1) { margin-top: 50px;}
                &:nth-child(2) { margin-top: 25px;}
            }
            .thumb { height: 300px;}
        }
    }
    &__list-column2 { display: flex; justify-content: space-between;
        a { text-decoration: none;}
        .item { width: 16rem;}
        .title { margin-top: 1rem; font-size: 1.4rem; line-height: 1.1; font-weight: bold; text-align: center;}
        .thumb { height: 11rem;
            >img { object-fit: cover; width: 100%; height: 100%;}
        }
        @include pc { justify-content: center;
            .item { flex: 0 1 430px;
                >a { display: flex; align-items: center; }
            }
            .title { padding-left: 30px; margin: 0; font-size: 17px; width: 50%; text-align: left;}
            .thumb { width: 50%;}
        }
    }
}

.topRecruit {
    &__container { margin-right: 5rem; margin-left: -1.5rem;
        @include pc { margin-right: 165px; margin-left: 0;}
    }
    &__content { background-color: #f9f9f9; position: relative;
        > a { display: block; padding: 3.5rem 0 5rem 1.5rem; position: relative; text-decoration: none; }
        .learnMore { margin-top: 4.5rem;}
        @include pc {
            > a { padding: 70px 0;}
            .learnMore { margin-top: 20px;}
        }
        &-bg {
            @include pc { margin-left: calc(50% - 50vw); background-color: #131313;}
        }
        &-header { margin-bottom: 4rem;
            @include pc { top: 70px; right: -126px; position: absolute; margin-bottom: 0;
                p { line-height: 1; text-align: center;}
                h2 { padding-left: 59px;}
                .white { color: #d4d4d4;}
            }
        }
        &-body {
            @include pc { position: relative; color: #fff;}
        }
    }
}
