@charset "UTF-8";

html { font-size: 2.666vw;
    @include pc { font-size: 125%;}
}
body {
    background: #fff; color:$txtBaseColor;
    font-size: 1.6rem; font-weight: 500; @include yu-go;
    letter-spacing: 0px;
    -webkit-text-size-adjust: 100%;
    @include pc { font-size: 16px;}
}

*,*::before,*::after { box-sizing:border-box;}
ul, ol { list-style: none;}
p { line-height: 1.6;}
img { max-width :100%; height: auto; vertical-align: bottom;
    @include pc { width: auto;}
}
button, input[type=submit], input[type=button] { -webkit-appearance: none; appearance: none;}

a { color:$txtLinkColor; text-decoration:underline;
    &:hover { text-decoration:none;}
    &:focus { outline:none;}
}

.palt { font-feature-settings : "palt";}
.justify { text-align: justify; text-justify: distribute;}

.responsive-video { position:relative; width:100%; padding-top:56.25%;
    iframe { position:absolute; top:0; right:0; width:100% !important; height:100% !important}
}
.wrapper { margin-right: 1.5rem; margin-left: 1.5rem;
    @include pc { max-width:1000px; margin-right: auto; margin-left: auto; padding-right: 20px; padding-left: 20px;}
}

#wrapper { overflow: hidden;}

#container { display: block;}

@include sp {
    .hide-sp { display: none!important;}
}
@include pc {
    .hide-pc { display: none!important;}
}
@include tab {
    .hide-tablet { display: none!important;}
}
