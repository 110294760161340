//#########################################
// photoOverlay
//#########################################
.photoOverlay { position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.5);
      -webkit-transform-origin: right top;
      -ms-transform-origin: right top;
      transform-origin: right top;
      -webkit-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-size: 14px; color: #fff; content: 'Learn more';
        transition:.4s; opacity: 0; margin-left: -10px;
    }
    &:hover::after { opacity: 1; margin-left: 0;}
}
a:hover .photoOverlay:before {
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

//#########################################
// hoverOverlay
//#########################################
.hoverOverlay {
    @include pc { top: 0; right: 0; bottom: 0; left: 0; position: absolute; display: block;
        &::before,&::after { height: 100%; width: 0; display: block; top: 0; left: 0; position: absolute; content: '';}
        &::after { background: inherit; }
        &::before { background: #666666;}
        &.red::before { background: #de1a2f;}
        >* { position: relative; z-index: 1;}
        a:hover & {
            &::before,&::after {width: 100%;}
            &::after {transition: .4s .3s;}
            &::before {transition: .4s 0s;}
        }
    }
}
//#########################################
// learnMore
//#########################################
.learnMore { text-decoration: none;
    .txt { font-family: 'Red Hat Text', sans-serif;}
    .icon { margin-left: 1rem; position: relative; display: inline-block;
        @include pc { margin-left: 15px;}
        &::after { width: 1.3rem; height: 1.3rem; display: inline-block; background: url(../images/icon_circle_arrow_black.png) no-repeat 0 / contain; vertical-align: middle; content: '';
            @include pc { transition: .2s .7s; height: 16px; width: 16px;
                a:hover & { opacity: 0; transition: .2s;}
            }
        }
        &.white::after { background-image: url(../images/icon_circle_arrow_white.png);}
    }
    .svg-arrow,
    .svg-circle { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);}
}
.svg-arrow path {
    fill: none;
    stroke: #62B0E2;
    stroke-dasharray: 14;
    stroke-dashoffset: 14;
    -webkit-transition: stroke-dashoffset 0.2s;
    -o-transition: stroke-dashoffset 0.2s;
    transition: stroke-dashoffset 0.2s;
    vertical-align: top;
}
a:hover .svg-arrow path {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 0.2s;
    transition-delay: 0.7s;
}
.svg-circle path {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #62B0E2;
  stroke-width: 1px;
  stroke-dasharray: 157;
  stroke-dashoffset: 157;
  -webkit-transition: stroke-dashoffset 0.4s;
  -o-transition: stroke-dashoffset 0.4s;
  transition: stroke-dashoffset 0.4s;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}
a:hover .svg-circle path{
    -webkit-transition: stroke-dashoffset 0.6s;
    -o-transition: stroke-dashoffset 0.6s;
    transition: stroke-dashoffset 0.6s;
    stroke-dashoffset: 0;
    -webkit-transition-delay: 0;
    -o-transition-delay: 0;
    transition-delay: 0;
}
