@charset "UTF-8";

$breakpoint: 768px;

@mixin pc {
    @media (min-width: $breakpoint) {
        @content;
    }
}
@mixin tab {
    @media (min-width: $breakpoint) and (max-width: 1024px) {
        @content;
    }
}
@mixin sp {
    @media (max-width: $breakpoint - 1px) {
        @content;
    }
}
@mixin ie { /* IE10以上 */
    @media all and (-ms-high-contrast: none) {
        @content;
    }
}
@mixin ie9 { /* IE9 */
    @media screen and (min-width: 0\0) and (min-resolution: .001dpcm) {
        @content;
    }
}
