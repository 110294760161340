@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; }

body { line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

nav ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:after, blockquote:before, q:after, q:before { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

ins { text-decoration: none; }

ins, mark { background-color: #ff9; color: #000; }

mark { font-style: italic; font-weight: 700; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted; cursor: help; }

table { border-collapse: collapse; border-spacing: 0; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

table { font-size: inherit; }

pre, code, kbd, samp, tt { font-family: monospace; *font-size: 100%; font: 115% monospace; line-height: 150%; }

h1, h2, h3, h4, h5, h6 { font-weight: normal; }

.clear { clear: both; }

br.clear { font-size: 1px; line-height: 100%; clear: both; }

div.clear { line-height: 100%; clear: both; }

.clearfix:after { content: ""; display: block; height: 0; clear: both; visibility: hidden; }

.clearfix { zoom: 1; }

* html .clearfix { height: 1%; }

.clearfix { display: block; }

.imgfix { font-size: 1px; line-height: 1px; }

html { font-size: 2.666vw; }

@media (min-width: 768px) { html { font-size: 125%; } }

body { background: #fff; color: #333; font-size: 1.6rem; font-weight: 500; font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", 'Hiragino Kaku Gothic Pro', "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", 'MS PGothic', sans-serif; /* IE9 */ letter-spacing: 0px; -webkit-text-size-adjust: 100%; }

@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) { body { font-family: "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", 'MS PGothic', sans-serif; } }

@media (min-width: 768px) { body { font-size: 16px; } }

*, *::before, *::after { box-sizing: border-box; }

ul, ol { list-style: none; }

p { line-height: 1.6; }

img { max-width: 100%; height: auto; vertical-align: bottom; }

@media (min-width: 768px) { img { width: auto; } }

button, input[type=submit], input[type=button] { -webkit-appearance: none; appearance: none; }

a { color: #333; text-decoration: underline; }

a:hover { text-decoration: none; }

a:focus { outline: none; }

.palt { font-feature-settings: "palt"; }

.justify { text-align: justify; text-justify: distribute; }

.responsive-video { position: relative; width: 100%; padding-top: 56.25%; }

.responsive-video iframe { position: absolute; top: 0; right: 0; width: 100% !important; height: 100% !important; }

.wrapper { margin-right: 1.5rem; margin-left: 1.5rem; }

@media (min-width: 768px) { .wrapper { max-width: 1000px; margin-right: auto; margin-left: auto; padding-right: 20px; padding-left: 20px; } }

#wrapper { overflow: hidden; }

#container { display: block; }

@media (max-width: 767px) { .hide-sp { display: none !important; } }

@media (min-width: 768px) { .hide-pc { display: none !important; } }

@media (min-width: 768px) and (max-width: 1024px) { .hide-tablet { display: none !important; } }

.photoOverlay { position: relative; }

.photoOverlay::before { content: ""; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); -webkit-transform-origin: right top; -ms-transform-origin: right top; transform-origin: right top; -webkit-transform: scale(0, 1); -ms-transform: scale(0, 1); transform: scale(0, 1); -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); }

.photoOverlay::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 14px; color: #fff; content: 'Learn more'; transition: .4s; opacity: 0; margin-left: -10px; }

.photoOverlay:hover::after { opacity: 1; margin-left: 0; }

a:hover .photoOverlay:before { -webkit-transform-origin: left top; -ms-transform-origin: left top; transform-origin: left top; -webkit-transform: scale(1, 1); -ms-transform: scale(1, 1); transform: scale(1, 1); }

@media (min-width: 768px) { .hoverOverlay { top: 0; right: 0; bottom: 0; left: 0; position: absolute; display: block; }
  .hoverOverlay::before, .hoverOverlay::after { height: 100%; width: 0; display: block; top: 0; left: 0; position: absolute; content: ''; }
  .hoverOverlay::after { background: inherit; }
  .hoverOverlay::before { background: #666666; }
  .hoverOverlay.red::before { background: #de1a2f; }
  .hoverOverlay > * { position: relative; z-index: 1; }
  a:hover .hoverOverlay::before, a:hover .hoverOverlay::after { width: 100%; }
  a:hover .hoverOverlay::after { transition: .4s .3s; }
  a:hover .hoverOverlay::before { transition: .4s 0s; } }

.learnMore { text-decoration: none; }

.learnMore .txt { font-family: 'Red Hat Text', sans-serif; }

.learnMore .icon { margin-left: 1rem; position: relative; display: inline-block; }

@media (min-width: 768px) { .learnMore .icon { margin-left: 15px; } }

.learnMore .icon::after { width: 1.3rem; height: 1.3rem; display: inline-block; background: url(../images/icon_circle_arrow_black.png) no-repeat 0/contain; vertical-align: middle; content: ''; }

@media (min-width: 768px) { .learnMore .icon::after { transition: .2s .7s; height: 16px; width: 16px; }
  a:hover .learnMore .icon::after { opacity: 0; transition: .2s; } }

.learnMore .icon.white::after { background-image: url(../images/icon_circle_arrow_white.png); }

.learnMore .svg-arrow, .learnMore .svg-circle { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.svg-arrow path { fill: none; stroke: #62B0E2; stroke-dasharray: 14; stroke-dashoffset: 14; -webkit-transition: stroke-dashoffset 0.2s; -o-transition: stroke-dashoffset 0.2s; transition: stroke-dashoffset 0.2s; vertical-align: top; }

a:hover .svg-arrow path { stroke-dashoffset: 0; transition: stroke-dashoffset 0.2s; transition-delay: 0.7s; }

.svg-circle path { fill-rule: evenodd; clip-rule: evenodd; fill: none; stroke: #62B0E2; stroke-width: 1px; stroke-dasharray: 157; stroke-dashoffset: 157; -webkit-transition: stroke-dashoffset 0.4s; -o-transition: stroke-dashoffset 0.4s; transition: stroke-dashoffset 0.4s; -webkit-transition-delay: .2s; -o-transition-delay: .2s; transition-delay: .2s; -webkit-transform: rotate(-90deg); -ms-transform: rotate(-90deg); transform: rotate(-90deg); -webkit-transform-origin: center center; -ms-transform-origin: center center; transform-origin: center center; }

a:hover .svg-circle path { -webkit-transition: stroke-dashoffset 0.6s; -o-transition: stroke-dashoffset 0.6s; transition: stroke-dashoffset 0.6s; stroke-dashoffset: 0; -webkit-transition-delay: 0; -o-transition-delay: 0; transition-delay: 0; }

.globalFooter { background: url(../images/footer01.jpg) no-repeat center -7rem/100% auto; padding: 21rem 0 0; }

.globalFooter a { text-decoration: none; color: #fff; }

@media (min-width: 768px) { .globalFooter { padding: 35.15% 0 0; background-position: center -32vw; background-size: cover; } }

.globalFooter__wrapper { position: relative; background-color: #0d0202; color: #fff; }

.globalFooter__bg { background-color: #0d0202; color: #fff; }

.globalFooter__main { padding: 4.5rem 0; position: relative; }

@media (min-width: 768px) { .globalFooter__main { padding: 80px 0; } }

.globalFooter__title { display: flex; align-items: center; justify-content: flex-end; margin-bottom: 1.5rem; color: #d4d4d4; text-align: center; }

.globalFooter__title .en { font-size: 2.4rem; }

.globalFooter__title .ja { margin: 0 1.5rem; font-size: 1.2rem; }

@media (min-width: 768px) { .globalFooter__title { justify-content: flex-start; } }

.globalFooter__body { text-align: center; position: relative; }

.globalFooter__body p.main { font-size: 1.5rem; }

.globalFooter__body p { font-size: 1rem; }

.globalFooter__body .link { right: 2.5rem; top: 0; position: absolute; width: 3.6rem; }

@media (min-width: 768px) { .globalFooter__body { text-align: left; }
  .globalFooter__body p { font-size: 18px; }
  .globalFooter__body .link { left: 400px; right: auto; } }

.globalFooter__copyright { padding: 1.5rem; position: relative; background-color: #e11428; color: #fff; font-size: 1.2rem; font-family: Roboto, sans-serif; text-align: center; }

@media (min-width: 768px) { .globalFooter__copyright { padding: 25px; font-size: 12px; } }

#goPagetop { position: fixed; right: 2rem; bottom: 4rem; z-index: 1000; }

#goPagetop a { display: block; width: 3.3rem; transform: rotate(-90deg); cursor: pointer; }

.globalHeader__bg { background-color: #fff; padding-left: 1.5rem; }

@media (min-width: 768px) { .globalHeader__bg { padding-left: 25px; } }

.globalHeader__content { display: flex; }

@media (max-width: 767px) { .globalHeader__content :not(.gnav) { position: relative; z-index: 101; } }

.globalHeader__logo { width: 11.3rem; align-self: center; }

@media (min-width: 768px) { .globalHeader__logo { width: 195px; } }

a.contactBtn { margin-left: auto; display: flex; justify-content: center; align-items: center; transition: opacity 0.3s; width: 7.5rem; background-color: #e11428; text-align: center; }

@media (min-width: 768px) { a.contactBtn:hover { opacity: 0.7; } }

a.contactBtn .txt_contact { width: 5.3rem; }

@media (min-width: 768px) { a.contactBtn { margin-left: 40px; height: 85px; width: 175px; }
  a.contactBtn .txt_contact { width: 97px; } }

@media (min-width: 768px) { .gnavMenuBtn { display: none; } }

/*-----------------------------------------------------------
    toggleMenu setting
-----------------------------------------------------------*/
[data-toggleMenu="main"] { top: 0; left: 0; position: fixed; z-index: 100; pointer-events: none; }

[data-toggleMenu="main"].open { pointer-events: auto; }

@media (min-width: 768px) { [data-toggleMenu="main"] { position: static; pointer-events: auto; } }

[data-toggleMenu="container"] { height: 100vh; width: 100vw; left: 0; top: -100vh; position: absolute; overflow: auto; -webkit-overflow-scrolling: touch; transition: transform 0.3s; }

.open [data-toggleMenu="container"] { transform: translateY(100vh); }

@media (min-width: 768px) { [data-toggleMenu="container"] { transform: none; position: static; height: auto; width: auto; overflow: visible; } }

.toggleMenu__button { display: block; width: 5rem; height: 5rem; position: relative; z-index: 10000; background: #fff url(../images/icon_menu.png) no-repeat center/2.6rem auto; }

.toggleMenu__button.open { background-image: url(../images/icon_menu_close.png); background-size: 2.7rem; }

.toggleMenu__overlay { top: 0; bottom: 0; right: 0; left: 0; position: fixed; z-index: 1; background-color: rgba(255, 255, 255, 0.3); }

/* gnav */
@media (min-width: 768px) { .gnav { align-self: center; margin-left: auto; } }

.gnav a { text-decoration: none; }

.gnav__container { background-color: #fff; }

@media (max-width: 767px) { .gnav__container { padding-top: 5rem; } }

.gnav__list { padding-top: 3rem; background: #fff; }

@media (min-width: 768px) { .gnav__list { display: flex; padding-top: 0; } }

.gnav__list > li { font-size: 1.8rem; }

@media (min-width: 768px) { .gnav__list > li { margin: 0 15px; font-size: 14px; } }

@media (max-width: 767px) { .gnav__list > li > a { padding: 3rem 4.5rem; display: block; } }

.gnav__list > li.contact a { color: #e11427; }

.topFv__bg { margin-bottom: 3.5rem; }

@media (min-width: 768px) { .topFv__bg { margin-bottom: 150px; } }

@media (min-width: 768px) { .topFv__container { position: relative; height: 44.5vw; min-height: 520px; } }

@media (min-width: 768px) { .topFv__mainVisual { height: 100%; }
  .topFv__mainVisual div { height: 100% !important; }
  .topFv__mainVisual img { height: 100%; max-width: none; } }

.topFv__content { background-color: #fff; }

@media (min-width: 768px) { .topFv__content { display: flex; flex-direction: column-reverse; position: absolute; top: 0; left: 50%; height: 100%; width: 50%; } }

.topFv__subVisual { display: flex; }

@media (min-width: 768px) { .topFv__subVisual { flex: 0 1 auto; height: 58%; } }

.topFv__subVisual-col { flex: 0 0 50%; overflow: hidden; }

@media (min-width: 768px) { .topFv__subVisual-col div { height: 100% !important; }
  .topFv__subVisual-col img { max-width: none; height: 100%; } }

.topFv__catchCopy { padding: 3.5rem 1.5rem; }

.topFv__catchCopy p { font-size: 1.5rem; line-height: 1.6; }

.topFv__catchCopy p.main { margin-bottom: 1.5rem; font-size: 2rem; line-height: 1.2; }

@media (min-width: 768px) { .topFv__catchCopy { flex: 1 1 auto; display: flex; align-items: center; padding: 0 0 0 11%; }
  .topFv__catchCopy p { font-size: 14px; }
  .topFv__catchCopy p.main { margin-bottom: 20px; font-size: 26px; } }

.topHeading { font-size: 1.4rem; color: #e11428; }

@media (min-width: 768px) { .topHeading { font-size: 24px; } }

.topHeading-en { margin-bottom: .5rem; font-size: 3.6rem; letter-spacing: .1em; color: #555555; }

@media (min-width: 768px) { .topHeading-en { margin-bottom: 10px; font-size: 48px; } }

.topAbout__container { margin: 0 -1.5rem 11rem 14rem; position: relative; }

.topAbout__container a { color: #fff; }

@media (min-width: 768px) { .topAbout__container { margin-left: 160px; margin-bottom: 60px; } }

.topAbout__header { position: absolute; top: 2.5rem; left: -5.4rem; z-index: 1; text-align: center; }

.topAbout__header .topHeading-en p { line-height: 1; font-size: 3.6rem; }

.topAbout__header .black { color: #555555; }

.topAbout__header .white { color: #fff; }

@media (min-width: 768px) { .topAbout__header { left: -165px; top: 50%; transform: translateY(-50%); text-align: left; } }

.topAbout__content { background-color: #131313; color: #fff; font-size: 1.5rem; position: relative; }

.topAbout__content > a { display: block; padding: 12rem 1.5rem 5rem 3rem; display: block; text-decoration: none; }

.topAbout__content p + p { margin-top: 1.5em; }

.topAbout__content .learnMore { margin-top: 4.5rem; }

@media (min-width: 768px) { .topAbout__content { font-size: 14px; }
  .topAbout__content > a { padding: 40px 40px 40px 210px; }
  .topAbout__content .learnMore { margin-top: 15px; } }

@media (min-width: 768px) { .topAbout__content-bg { margin-right: calc(50% - 50vw); background-color: #131313; } }

.topAbout__content-body { position: relative; }

.topBusinessOverview__container { margin-bottom: 8.5rem; }

@media (min-width: 768px) { .topBusinessOverview__container { margin-bottom: 75px; } }

.topBusinessOverview__header { margin-bottom: 5rem; text-align: center; }

@media (min-width: 768px) { .topBusinessOverview__header { margin-bottom: 110px; } }

.topBusinessOverview__intro { margin-bottom: 8.5rem; font-size: 1.5rem; }

.topBusinessOverview__intro p { line-height: 1.33; }

.topBusinessOverview__intro .learnMore { margin-top: 3rem; text-align: center; }

.topBusinessOverview__intro .learnMore a { text-decoration: none; }

.topBusinessOverview__content { padding-top: 14rem; position: relative; }

@media (min-width: 768px) { .topBusinessOverview__content { padding-top: 115px; width: 960px; } }

.topBusinessOverview__content-image { height: 30rem; width: 30rem; right: -1.5rem; top: 0; position: absolute; z-index: 1; overflow: hidden; }

.topBusinessOverview__content-image > img { max-width: none; height: 100%; }

@media (min-width: 768px) { .topBusinessOverview__content-image { height: 493px; width: 617px; right: -155px; } }

.topBusinessOverview__content-body { width: 30rem; height: 24rem; left: -1rem; position: relative; background-color: #f9f0f1; }

@media (min-width: 768px) { .topBusinessOverview__content-body { height: 475px; width: 617px; left: 0; }
  .topBusinessOverview__content-body a { display: block; height: 100%; padding: 120px 0 0 80px; text-decoration: none; }
  .topBusinessOverview__content-body p { font-size: 24px; line-height: 1.75; }
  .topBusinessOverview__content-body .learnMore { margin-top: 30px; } }

.topBusinessOverview__content-body-bg { background-color: #f9f0f1; }

.topBusinessOverview__content-body-inner { position: relative; }

.topBusinessOverviewItems__container { padding-bottom: 10rem; }

.topBusinessOverviewItems__list-column3 a { text-decoration: none; }

.topBusinessOverviewItems__list-column3 .title { margin-top: 1rem; font-size: 1.5rem; line-height: 1.1; font-weight: bold; text-align: center; }

.topBusinessOverviewItems__list-column3 .thumb { height: 16rem; }

.topBusinessOverviewItems__list-column3 .thumb > img { object-fit: cover; width: 100%; height: 100%; }

@media (max-width: 767px) { .topBusinessOverviewItems__list-column3 { margin-bottom: 3rem; }
  .topBusinessOverviewItems__list-column3 li + li { margin-top: 3rem; } }

@media (min-width: 768px) { .topBusinessOverviewItems__list-column3 { margin-bottom: 120px; display: flex; justify-content: space-between; }
  .topBusinessOverviewItems__list-column3 .title { font-size: 18px; }
  .topBusinessOverviewItems__list-column3 .item { flex: 0 1 300px; }
  .topBusinessOverviewItems__list-column3 .item:not(:first-child) { margin-left: 10px; }
  .topBusinessOverviewItems__list-column3 .item:nth-child(1) { margin-top: 50px; }
  .topBusinessOverviewItems__list-column3 .item:nth-child(2) { margin-top: 25px; }
  .topBusinessOverviewItems__list-column3 .thumb { height: 300px; } }

.topBusinessOverviewItems__list-column2 { display: flex; justify-content: space-between; }

.topBusinessOverviewItems__list-column2 a { text-decoration: none; }

.topBusinessOverviewItems__list-column2 .item { width: 16rem; }

.topBusinessOverviewItems__list-column2 .title { margin-top: 1rem; font-size: 1.4rem; line-height: 1.1; font-weight: bold; text-align: center; }

.topBusinessOverviewItems__list-column2 .thumb { height: 11rem; }

.topBusinessOverviewItems__list-column2 .thumb > img { object-fit: cover; width: 100%; height: 100%; }

@media (min-width: 768px) { .topBusinessOverviewItems__list-column2 { justify-content: center; }
  .topBusinessOverviewItems__list-column2 .item { flex: 0 1 430px; }
  .topBusinessOverviewItems__list-column2 .item > a { display: flex; align-items: center; }
  .topBusinessOverviewItems__list-column2 .title { padding-left: 30px; margin: 0; font-size: 17px; width: 50%; text-align: left; }
  .topBusinessOverviewItems__list-column2 .thumb { width: 50%; } }

.topRecruit__container { margin-right: 5rem; margin-left: -1.5rem; }

@media (min-width: 768px) { .topRecruit__container { margin-right: 165px; margin-left: 0; } }

.topRecruit__content { background-color: #f9f9f9; position: relative; }

.topRecruit__content > a { display: block; padding: 3.5rem 0 5rem 1.5rem; position: relative; text-decoration: none; }

.topRecruit__content .learnMore { margin-top: 4.5rem; }

@media (min-width: 768px) { .topRecruit__content > a { padding: 70px 0; }
  .topRecruit__content .learnMore { margin-top: 20px; } }

@media (min-width: 768px) { .topRecruit__content-bg { margin-left: calc(50% - 50vw); background-color: #131313; } }

.topRecruit__content-header { margin-bottom: 4rem; }

@media (min-width: 768px) { .topRecruit__content-header { top: 70px; right: -126px; position: absolute; margin-bottom: 0; }
  .topRecruit__content-header p { line-height: 1; text-align: center; }
  .topRecruit__content-header h2 { padding-left: 59px; }
  .topRecruit__content-header .white { color: #d4d4d4; } }

@media (min-width: 768px) { .topRecruit__content-body { position: relative; color: #fff; } }

.ff-redhat { font-family: 'Red Hat Text', sans-serif; }
