@charset "UTF-8";

.globalHeader {
    &__bg { background-color: #fff; padding-left: 1.5rem;
        @include pc { padding-left: 25px;}
    }
    &__content{ display: flex;
        @include sp {
            :not(.gnav) { position: relative; z-index: 101;}
        }
    }
    &__logo { width: 11.3rem; align-self: center;
        @include pc { width: 195px;}
    }
}
a.contactBtn { margin-left: auto; @include flex-center; @include hover; width: 7.5rem; background-color: #e11428; text-align: center;
    .txt_contact { width: 5.3rem;}
    @include pc { margin-left: 40px; height: 85px; width: 175px;
        .txt_contact { width: 97px;}
    }
}
.gnavMenuBtn {
    @include pc { display: none;}
}
