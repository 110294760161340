@charset "UTF-8";

// font family
@mixin yu-min { font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", 'Hiragino Mincho Pro', 'HiraMinProN-W3', 'MS Mincho', serif;
    @include ie9 { font-family: "ヒラギノ明朝 Pro W3", 'Hiragino Mincho Pro', 'MS Mincho', serif;}
}
@mixin yu-go { font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", 'Hiragino Kaku Gothic Pro', "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", 'MS PGothic', sans-serif;
    @include ie9 { font-family: "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", 'MS PGothic', sans-serif;}
}

// mouse hover
@mixin hover { transition: opacity 0.3s;
    @include pc { &:hover { opacity:0.7;}}
}

// position
@mixin center-v {
    position: absolute; top: 50%; transform: translateY(-50%);
}
@mixin center-h {
    position: absolute; left: 50%; transform: translateX(-50%);
}
@mixin center-vh {
    position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
}

// block size
@mixin fullsize {
    margin-right: calc(50% - 50vw); margin-left: calc(50% - 50vw);
}
