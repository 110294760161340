@charset "UTF-8";

.globalFooter { background:url(../images/footer01.jpg) no-repeat center -7rem / 100% auto; padding: 21rem 0 0;
    a {text-decoration: none; color: #fff;}
    @include pc { padding: 35.15% 0 0; background-position: center -32vw; background-size: cover;}

    &__wrapper { position: relative; background-color: #0d0202; color: #fff;}
    &__bg { background-color: #0d0202; color: #fff;}
    &__main { padding: 4.5rem 0; position: relative;
        @include pc { padding: 80px 0;}
    }
    &__title { display: flex; align-items: center; justify-content: flex-end; margin-bottom: 1.5rem; color: #d4d4d4; text-align: center;
        .en { font-size: 2.4rem;}
        .ja { margin: 0 1.5rem; font-size: 1.2rem;}
        @include pc { justify-content: flex-start;

        }
    }
    &__body { text-align: center; position: relative;
        p.main { font-size: 1.5rem;}
        p { font-size: 1rem;}
        .link { right: 2.5rem; top: 0; position: absolute; width: 3.6rem;}
        @include pc { text-align: left;
            p { font-size: 18px;}
            .link { left: 400px; right: auto;}
        }
    }
    &__copyright { padding: 1.5rem; position: relative; background-color: #e11428; color: #fff; font-size: 1.2rem; font-family: Roboto, sans-serif;; text-align: center;
        @include pc { padding: 25px; font-size: 12px;}
    }
}

#goPagetop { position: fixed; right: 2rem; bottom: 4rem; z-index: 1000;
   a { display: block; width: 3.3rem; transform:rotate(-90deg); cursor: pointer;}
}
